import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import Img from "gatsby-image";
import SEO from '../../components/seo';
import ImageLandscape from '../../components/sub/landscape.js';
import PortraitsTwo from '../../components/sub/portraitx2.js';
import Image from '../../components/image';
export const frontmatter = {
  title: "2018 Thought-Starters: 4 Key Challenges & How to Stay Ahead",
  date: "2017-12-21",
  author: "Lauren Lyons",
  authorTitle: "Content Editor, The Doneger Group",
  teaserImage: "../../../static/media/20171221-0.jpg",
  path: "2017-12-21-2018-Thought-Starters",
  teaserText: "In 2018, as the fashion industry faces four key industry challenges, we’re outlining how to stay ahead with best in class practices and innovation."
};
const layoutProps = {
  frontmatter
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>

      <SEO title="2018 Thought-Starters: 4 Key Challenges & How to Stay Ahead" description="In 2018, as the fashion industry faces four key industry challenges, we’re outlining how to stay ahead with best in class practices and innovation." author='Lauren Lyons' keywords={['Robots']} />
      <MDXTag name="p" components={components}>{`As we look back at 2017, we’ve seen the rise of the connected consumer and navigated all-encompassing shopper expectations. Themes of hyper-connectivity and mindful consumption have shaped an unprecedented consumer outlook. In 2018, as the fashion industry faces four key industry challenges, we’re outlining how to stay ahead with best in class practices and innovation.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`The Challenge: Uncertainty & Volatility`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Unpredictability has become today’s norm, perfectly personified in Bitcoin’s erratic rise that has cryptocurrencies moving into the mainstream. With uncertainty at both a global and domestic level, consumers are countering by looking to new influencers, cultivating a new work culture and lauding the authentic/transparent.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Our Recommendation:`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Adopt a startup mentality by staying open to new talent, collaborations and creativity. Consider the wild success of diverse campaigns we saw in 2017. Think of how your brand can reflect these changing values through authentic voices and campaigns. In the age of uncertainty, be a brand where consumers can place their trust.`}</MDXTag>
      <ImageLandscape imageSrc="20171221-1.jpg" caption="Sephora 2017 Holiday Campaign" />
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`The Challenge: Online & Omnichannel Competition`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`In today’s climate of ever-slowing foot traffic, those who are e-com-based and D2C are positioned to excel. At the same time, the influx of e-tail startups has soared, making it necessary to stand out in searches, product, personality and delivery. The need for omni experiences are that much more important heading into 2018.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Our Recommendation:`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Invest in what’s unique. Personalization in messaging and product curation will become more important to consumers. The idea of “made for me” across skin care, fashion, beauty, health and home will become an expectation. As companies increasingly incorporate AI capabilities, we expect to see brands offer hyper-tailored experiences both online and IRL.`}</MDXTag>
      <ImageLandscape imageSrc="20171221-2.jpg" caption="Function of Beauty" />
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`The Challenge: Value Chain Improvement`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`A redefined cost structure has taken root, with both consumer and brand taking personal interest in a circular economy. With the growing importance of sustainable practices, we see this as a new way to provide innovation and newness. From 3-D printed product and robotic production to design and predictive shipping algorithms, reducing waste is the equation of 2018.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Our Recommendation:`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Manufacturing improvements, from creation to delivery pose as a huge opportunity heading into 2018. From brands like Stitch Fix and Amazon using algorithms to design and stock product to companies like Adidas and Nike moving solidly into the 3-D printing space, think about how data and new technologies can help you to serve up better quality and value.`}</MDXTag>
      <ImageLandscape imageSrc="20171221-3.jpg" caption="Stitch Fix" />
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`The Challenge: Foot Traffic`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`The reinvention of the store is a hot topic as retailers attempt to inspire a consumer looking for entertainment. Seamlessly moving through channels, today’s consumer expects zero friction from start to finish. As retailers look to better address this, we’re seeing stores and pop-ups beginning to incorporate new elements into the in-store experience.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Our Recommendation:`}</MDXTag><MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Experimentation is paying off, so consider offering new experiences eliciting a better consumer response. From Outdoor Voices’ climbing wall or Reformation’s tech-enhanced San Francisco outpost, to brands like Forever21 and Madewell expanding into beauty or those like Away and Moon Juice incorporating cafés, creating a destination space is paramount.`}</MDXTag>
      <ImageLandscape imageSrc="20171221-4.jpg" caption="Tamburins Concept Store" />
           </MDXTag>;
  }

}
export const _frontmatter = {};
    